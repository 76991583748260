import React from "react"
import styled from "styled-components"
import { Layout, Appointment } from "../components"

const AppointmentPage = ({ data }) => {
  return (
    <Wrapper>
      <Layout>
        <Appointment/>
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-white);
  nav {
    background: var(--clr-primary-5);
  }
`

export default AppointmentPage
